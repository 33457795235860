<template>
    <CCard>
      <loading-overlay :active="isLoadingGeneral" :is-full-page="true" loader="bars" />
      <CCardHeader class="text-center dark text-white">
        <CRow>
          <CCol sm="11" class="d-flex ">{{titulo}}</CCol>
          <CCol sm="1" class="d-flex justify-content-end">
            <button type="button" aria-label="Close" class="close" @click="isTare?SelectTare(false):SelectOrderNo(false)">×</button>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
          <CCol sm="12">
            <dataTableExtended
              class="align-center-row-datatable"
              :fields="fields"
              :items="computedOptionsList"
              :items-per-page="5"
              column-filter
              pagination
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :noItemsView="tableText.noItemsViewText"
              hover
              small
              sorter
            >
              <template #OrderStatusDs="{item}" v-if="!isTare">
                <td class="center-cell">
                  <CBadge>
                    {{ item.OrderStatusDs }}
                  </CBadge>
                </td>
              </template>
              <template #checked="{ item }">
                <td class="center-cell">
                  <CInputCheckbox
                    v-if="isTare"
                    custom
                    :checked="item.Checked"
                    class="checkbook-filter"
                    @update:checked="SelectTare(true, $event, item)"
                  />
                  <CInputCheckbox
                    v-else
                    custom
                    :checked="item.Checked"
                    class="checkbook-filter"
                    @update:checked="SelectOrderNo(true, $event, item)"
                  />
                </td>
              </template>
            </dataTableExtended>
          </CCol>
      </CCardBody>
    </CCard>
  </template>
  
  <script>
  import UpperCase  from '@/_validations/uppercase-directive';
  import GeneralMixin from '@/_mixins/general';
  import { DateFormater, tableTextTranslatedHelpers, NumberFormater } from '@/_helpers/funciones';
  
  //Data
  function data() {
    return {
      Options: [],
      isLoadingGeneral: false,
    }
  }
  
  //Method 
  async function SelectTare(closet, event, item){
    if(closet){
      this.$emit('set-Tare-weight', false, event, item.Weight, item.StowagePlanningLogWeigthId);
    }else{
      this.$emit('set-Tare-weight', false);
    }
  }

  async function SelectOrderNo(closet, event, item){
    if(closet){
      this.$emit('set-NoOrder', false, event, item.OrderId, item.OrderCode, item.OrderStatusDs, item.ColorStatus, item.FormatedDate, item.FormatedFinishDate, item.StowagePlanningLogFullWeigthId,
        item.StowagePlanningLogTareWeigthId, item.TareWeight);
    }else{
      this.$emit('set-NoOrder', false);
    }
  }

  function tableText(){
    return tableTextTranslatedHelpers(this);
  }

  function fields(){
    if (this.isTare) {
      return [
        { key: 'Nro', label: '#', filter: false, _style: 'width:3%;', _classes:'text-center' },
        { key: 'OrderCode', label: this.$t('label.noOrder'), _style:'width:24%', _classes:'text-center' },
        { key: 'TareWeightKgm', label: this.$t('label.Taraweight')+'(KGM)', _style:'width:18%; text-align:center', _classes:'text-center' },
        { key: 'TareWeightTon', label: this.$t('label.Taraweight')+this.$t('label.metricTonnageAcronym'), _style:'width:18%;', _classes:'text-center' },
        { key: 'TruckWeghingScaleName', label: this.$t('label.truckScale'), _style:'width:15%', _classes:'text-center' },
        { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width: 19%;', _classes:'text-center'},
        {
          label: '',
          key: 'checked',
          sorter: false, filter: false,
          _classes:"center-cell",
          _style:'min-width:45px; text-align:center; width: 3%',
        },
      ];
    } else {
      return [
        { key: 'Nro', label: '#', filter: false, _style: 'width:1%; text-align:center;', _classes:'text-center' },
        { key: 'OrderCode', label: this.$t('label.noOrder'), _style:'width:20%', _classes:'text-center' },
        { key: 'FormatedDate', label: this.$t('label.emissionDate'), _style: 'width: 22%; text-align:center;', _classes:'text-center'},
        { key: 'FormatedFinishDate', label: this.$t('label.shippingDate'), _style:'width:20%; text-align:center;', _classes:'text-center' },
        { key: 'TotalTime', label: this.$t('label.totalTime'), _style:'width:15%, text-align:center;', _classes:'text-center' },
        { key: 'OrderStatusDs', label: this.$t('label.status'), _style:'width:10%; text-align:center;', _classes:'text-center' },
        {
          label: '',
          key: 'checked',
          sorter: false, filter: false,
          _classes:"center-cell",
          _style:'min-width:45px; text-align:center;',
        },
      ];
    }
    
  }
  
  //Computed
  function titulo() {
    if (this.isTare) {
      return this.$t('label.BinTareweightlist')+': '+this.BinAlias;
    } else {
      return this.$t('label.BinOrderNoList')+': '+this.BinAlias;
    }
  }

  function computedOptionsList() {
    if (this.Options ==[]) return this.Options = [];
    if (this.Options.length > 0) {
      return this.Options.map((item) => {
        if(this.isTare){
          return {
            ...item,
            Checked: item.StowagePlanningLogWeigthId==this.StowagePlanningLogTareWeigthId ? true : false,
            TareWeightKgm: NumberFormater.formatNumber(item.Weight, 4),
            TareWeightTon: NumberFormater.formatNumber(item.WeightTon, 4),
            FormatedDate: item.TransactionDate
              ? DateFormater.formatDateTimeWithSlash(item.TransactionDate)
              : 'N/A',
          };
        }else{
          let _lang = this.$i18n.locale;
          return {
            ...item,
            Checked: item.OrderId==this.NoOrder ? true : false,
            OrderStatusDs: _lang=='en' ? item.OrderStatusDsEn : item.OrderStatusDsEs,
            FormatedFinishDate: item.FinishDate ? DateFormater.formatDateTimeWithSlash(item.FinishDate) : 'N/A',
            FormatedDate: item.TransactionDate
              ? DateFormater.formatDateTimeWithSlash(item.TransactionDate)
              : 'N/A',
          };
        }
        
        });
      }
    }
  
  export default {
    name:  'collapse-tare-or-orden',
    mixins: [GeneralMixin],
    props: {showCollapse: Boolean, Option: Array, BinAlias: String, isTare: Boolean, StowagePlanningLogTareWeigthId: String, NoOrder: String},
    data,
    directives: UpperCase,
    methods: {
      SelectTare,
      SelectOrderNo,
    },
    computed: {
      computedOptionsList,      
      tableText,
      fields,
      titulo,
    },
    watch: {
      showCollapse: function(val){
        if(val){
          this.Options = this.Option;
        }else{
          this.Options = [];
        }
      },
      Option: function(val){
        if(val.length > 0){
          this.Options = val;
        }
      }
    }
  }
  </script>